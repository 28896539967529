import * as React from "react";
import Button from "../Button/Button";
import "./be-a-manager.scss";

const BeManger = () => {
  return (
    <div className="be-a-manager-container">
      <div className="be-a-manager-button-wrapper">
        <Button dark route="https://nandoca.typeform.com/be-a-manager" label="Self nominate" />
      </div>
    </div>
  );
};

export default BeManger;
